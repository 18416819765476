@import "~antd/dist/antd.css";

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.4);
}

:root {
  --default-background-color: white;
  --default-font-color: #262d31;
  --default-font-size: 14px;
}

html,
body {
  background: var(--default-background-color);
  color: var(--default-font-color);
  font-size: var(--default-font-size);
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

* {
  animation: fadeIn 0.3s;
  max-width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fallDown {
  from {
    transform: translateY(-400px);
  }

  to {
    transform: translateY(0);
  }
}
