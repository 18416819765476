.app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app form {
  width: 50vw;
  max-width: 500px;
}

@media (max-width: 500px) {
  .app {
    max-width: 400px;
  }

  .app form {
    max-width: 95vw;
    margin: auto;
  }

  form#login {
    width: 90%;
  }

  .search-by-pnr-card {
    width: 90vw;
  }

  .search-customer-card {
    width: 90vw;
  }

  .ant-card form {
    width: 100%;
  }

  .flight-segment .ant-card-head-title {
    font-size: 70%;
  }
}

.ant-row {
  align-items: baseline;
}

.ant-col ~ .ant-col {
  margin: 10px;
}

.ant-card {
  margin: 5px;
}

.ant-card-body {
  padding: 10px;
}

.nav-item:hover,
.nav-item:active {
  transform: scale(1.3);
}

.ant-card:hover {
  border: 1px solid green;
}

.customer-result-card:hover,
.customer-result-card:active {
  transform: scale(1.05);
  border: 1px solid #1890ff;
}

.booking-by-customer:hover,
.booking-by-customer:active {
  cursor: pointer;
  transform: scale(1.03);
  border: 1px solid #1890ff;
}
